Finali.into {
    text-align: justify
}

.track {
    text-align: justify
}

.carousel .thumb {
    border-color: #000 !important;
}

.carousel .thumb.selected {
    border-color: #333 !important;
}

.intro img {
    width: 100%;
    vertical-align: middle;
    border: 0;
    display: inline-block
}

.intro {
    text-align: justify
}

.magician-carousel {
    background: #111;
    padding-top: 30px;
}

.carousel-status {
    display: none
}

.highlight {
    color: #31c27c
}
