@import url('https://fonts.googleapis.com/css2?family=PT+Serif&display=swap');

body, Button {
    background-color: #000;
    text-align: center;
    font-family: 'PT Serif', serif;
    color: #dfdfde;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 150%
}

a {
    color: #31c27c;
    text-decoration: none
}

h1 {
    font-size: 200%;
    line-height: 120%;
}

h1, h2, h3 {
    font-weight: normal;
    text-transform: uppercase;
}

.copyright {
    margin: 100px
}


button {
    background: none !important;
    border: none;
    padding: 0 !important;
    font-size: 100%;
    color: #31c27c;
    text-decoration: none;
    cursor: pointer;
}